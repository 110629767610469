// @flow

//Libs
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import ActionMenu from "../../../ActionMenu";
import classNames from "classnames";

import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import BlockIcon from "@material-ui/icons/Block";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Grow from "@material-ui/core/Grow";
import Typography from "@material-ui/core/Typography";

import style from "../style.module.scss";
import { displayDate } from "@utils/date";
import { REPORTITEM } from "@constants";
import { updateVersionName } from "@stores/update-version-name";
import { refetch as refetchForms } from "@stores/forms-summary";

type Props = {
    id: string,
    record: *,
    index: number,
    callbacks: *,
    canActivateVersions?: boolean,
    canDeactivateVersions?: boolean,
    canCancelVersions?: boolean,
    extraParams?: *,
};

const ReportTypesVersionsRow = ({
    record,
    index,
    id,
    callbacks,
    canActivateVersions,
    canDeactivateVersions,
    canCancelVersions,
    extraParams,
}: Props) => {
    const dispatch = useDispatch();
    const [isEditing, setIsEditing] = useState(false);
    const [isHovered, setIsHovered] = useState(null);
    const [versionName, setVersionName] = useState(record.name);
    const [originalVersionName, setOriginalVersionName] = useState(record.name);

    useEffect(() => {
        setVersionName(record.name);
        setOriginalVersionName(record.name);
    }, [record]);

    const handleEditClick = () => {
        setOriginalVersionName(versionName);
        setIsEditing(true);
    };

    const handleSaveClick = (event: *) => {
        event.stopPropagation();
        if (versionName !== originalVersionName) {
            let versionId = record.id || undefined;
            let formId = extraParams?.selectedReportType?.id || undefined;

            if (formId && versionId) {
                dispatch(
                    updateVersionName(formId, versionId, {
                        versionName: versionName,
                    }),
                ).then((response: *) => {
                    if (response) {
                        dispatch(refetchForms());
                        setIsEditing(false);
                    }
                });
            }
        }
    };

    const handleCancelClick = (event: *) => {
        event.stopPropagation();
        setVersionName(originalVersionName);
        setIsEditing(false);
    };

    const handleChange = (event: *) => {
        setVersionName(event.target.value);
    };

    return (
        <TableRow
            id={`${id}-record-${record.id}`}
            key={`${id}-record-${record.id}`}
            tabIndex={-1}
            className={classNames(style.tableRow, style.noClick, {
                [style.odd]: index % 2 === 1,
            })}
            onMouseEnter={() => setIsHovered("versionName")}
            onMouseLeave={() => setIsHovered(null)}
        >
            <TableCell
                style={{ whiteSpace: "nowrap" }}
            >{`V ${record.number}`}</TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}>
                {isEditing ? (
                    <Grow in={isEditing}>
                        <Box display="flex" alignItems="center">
                            <TextField
                                value={versionName || ""}
                                onChange={event => handleChange(event)}
                                onClick={e => e.stopPropagation()}
                            />
                            <IconButton
                                size="small"
                                onClick={event => handleSaveClick(event)}
                            >
                                <SaveIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                                size="small"
                                onClick={event => handleCancelClick(event)}
                            >
                                <CancelIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    </Grow>
                ) : (
                    <Box display="flex" alignItems="center">
                        <Typography>{versionName}</Typography>
                        <Box
                            style={{
                                width: 24,
                                height: 24,
                                marginLeft: "auto",
                            }}
                        >
                            {isHovered === "versionName" && !isEditing && (
                                <IconButton
                                    size="small"
                                    onClick={() => handleEditClick()}
                                    style={{ marginLeft: "auto" }}
                                >
                                    <EditIcon
                                        fontSize="small"
                                        color="primary"
                                    />
                                </IconButton>
                            )}
                        </Box>
                    </Box>
                )}
            </TableCell>
            <TableCell>
                {REPORTITEM.ACTIVATION_STATUS[record.activationStatus]}
            </TableCell>
            <TableCell>{displayDate(record.activationDate)}</TableCell>
            <TableCell>{displayDate(record.deactivationDate)}</TableCell>
            <TableCell
                align="left"
                style={{
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                }}
            >
                {record.remark}
            </TableCell>
            <TableCell>
                <ActionMenu
                    id={`${id}-action-menu`}
                    size="small"
                    extraMenuProps={{
                        anchorOrigin: {
                            horizontal: "right",
                            vertical: "bottom",
                        },
                        getContentAnchorEl: null,
                        elevation: 1,
                    }}
                    items={[
                        {
                            text: "Activeer versie",
                            icon: <EventAvailableIcon fontSize="small" />,
                            action: () =>
                                callbacks.onEdit &&
                                callbacks.onEdit("ACTIVATE", record),
                            disabled:
                                !canActivateVersions ||
                                record.activationStatus === "ACTIVE" ||
                                record.activationStatus ===
                                    "ACTIVATION_SCHEDULED" ||
                                record.activationStatus ===
                                    "DEACTIVATION_SCHEDULED",
                        },
                        {
                            text: "Deactiveer versie",
                            icon: <EventBusyIcon fontSize="small" />,
                            action: () =>
                                callbacks.onEdit &&
                                callbacks.onEdit("DEACTIVATE", record),
                            disabled:
                                !canDeactivateVersions ||
                                record.activationStatus === "INACTIVE",
                        },
                        {
                            text: "Annuleer planning",
                            icon: <BlockIcon fontSize="small" />,
                            action: () =>
                                callbacks.onEdit &&
                                callbacks.onEdit("CANCEL", record),
                            disabled:
                                !canCancelVersions ||
                                !record.activationStatus ===
                                    "ACTIVATION_SCHEDULED" ||
                                !record.activationStatus ===
                                    "DEACTIVATION_SCHEDULED" ||
                                record.activationStatus === "INACTIVE" ||
                                record.activationStatus === "ACTIVE",
                        },
                    ]}
                />
            </TableCell>
        </TableRow>
    );
};

export default ReportTypesVersionsRow;
