// @flow

import style from "./style.module.scss";

//import libs
import React, { Fragment, useState, useCallback, useEffect } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import TextField from "@material-ui/core/TextField";

import { useSelector, useDispatch } from "react-redux";
import { addModule as addModuleAction } from "@stores/visit-modules";
import { load as getFormsForInspectionAction } from "@stores/get-forms-for-inspection";
import {
    load as getFormsVersionsForInspectionAction,
    clear as clearReportTypeVersions,
} from "@stores/get-forms-versions-for-inspection";
import { load as getFormsWithVersionByIdAction } from "@stores/get-forms-for-inspection-by-version";

//own
import FormsList from "../FormsList";
import SelectedModulesList from "../SelectedModulesList";
import {
    Typography,
    LoadingBox,
    LoadingButton,
    Modal,
} from "@components/Shared";
import type { AddModuleOptions, ModuleType } from "@types";
import { sortOnProperty, isArrayWithContent } from "@utils";

/**
 *   Hoisted
 */
const addModulesLoadingSelector = state => state.addModule.loading;
const formsSummarySelector = state => state.formsSummary;
const formsForInspectionSelector = state => state.getFormsForInspection;
const formsVersionsForInspectionSelector = state =>
    state.getFormsVersionsForInspection;
const formsWithVersionSelector = state => state.getFormsForInspectionByVersion;

/**
 *   COMPONENT
 */
type Props = {
    modules: *,
    id: string,
    selectedModulesLoading: boolean,
    assignment: *,
    selectedModulesNumber: number,
    visitId: string,
    currentUser: *,
    addModulesLoading?: boolean,
    onDeleteSelectedOrParent: () => void,
    selectedInstanceId?: string,
    selectedParentInstanceId?: string,
};

const SelectModules = ({
    modules,
    id,
    assignment,
    selectedModulesNumber,
    selectedModulesLoading,
    currentUser,
    visitId,
    onDeleteSelectedOrParent,
    selectedInstanceId,
    selectedParentInstanceId,
}: Props) => {
    const dispatch = useDispatch();
    const [openElements, setOpenElements] = useState([]);
    const [subInNeedOfParent, setSubInNeedOfParent] = useState<any>(null);
    const [availableParents, setAvailableParents] = useState<any>(null);
    const [selectedReportType, setSelectedReportType] = useState<any>("");
    const [
        selectedReportTypeVersion,
        setSelectedReportTypeVersion,
    ] = useState<any>("");
    const addModulesLoading = useSelector(addModulesLoadingSelector);
    const cancelParentModal = useCallback(() => {
        setSubInNeedOfParent(null);
        setAvailableParents(null);
    }, []);
    const formsSummaryStore = useSelector(formsSummarySelector);
    const formsForInspectionStore = useSelector(formsForInspectionSelector);
    const formsVersionsForInspectionStore = useSelector(
        formsVersionsForInspectionSelector,
    );
    const formsWithVersionStore = useSelector(formsWithVersionSelector);
    const reportTypes = formsForInspectionStore?.data;
    const reportTypeVersions = formsVersionsForInspectionStore?.data;
    const formsWithVersion = formsWithVersionStore?.data;
    const formsListLoading = formsWithVersionStore?.loading;

    useEffect(() => {
        dispatch(
            getFormsForInspectionAction(
                {
                    path: {
                        inspectionId: assignment.inspectionId,
                        visitId: visitId,
                    },
                },
                true,
            ),
        ).then((response: *) => {
            if (response && response !== "LOADING") {
                if (modules.length > 0) {
                    setSelectedReportType(response.payload[0]);
                }
            }
        });
    }, []);

    useEffect(() => {
        if (!selectedReportType) return;
        dispatch(
            getFormsVersionsForInspectionAction(
                {
                    path: {
                        inspectionId: assignment.inspectionId,
                        visitId: visitId,
                        templateId: selectedReportType?.id,
                    },
                },
                true,
            ),
        ).then((response: *) => {
            if (response && response !== "LOADING" && selectedReportType) {
                setSelectedReportTypeVersion(response.payload[0]);
            }
        });
    }, [selectedReportType]);

    useEffect(() => {
        if (!selectedReportTypeVersion) return;

        dispatch(
            getFormsWithVersionByIdAction(
                {
                    path: {
                        inspectionId: assignment.inspectionId,
                        visitId: visitId,
                        templateId: selectedReportType?.id,
                        versionId: selectedReportTypeVersion?.versionId,
                    },
                },
                true,
            ),
        );
    }, [selectedReportTypeVersion]);

    useEffect(() => {
        if (modules.length > 0) {
            dispatch(
                getFormsForInspectionAction(
                    {
                        path: {
                            inspectionId: assignment.inspectionId,
                            visitId: visitId,
                        },
                    },
                    true,
                ),
            ).then((response: *) => {
                if (response && response !== "LOADING") {
                    setSelectedReportType(response.payload[0]);
                    if (formsWithVersion.length > 0) {
                        setSelectedReportTypeVersion(reportTypeVersions[0]);
                    }
                }
            });
        }
        if (modules.length === 0) {
            dispatch(
                getFormsForInspectionAction(
                    {
                        path: {
                            inspectionId: assignment.inspectionId,
                            visitId: visitId,
                        },
                    },
                    true,
                ),
            );
            setSelectedReportType(null);
            setSelectedReportTypeVersion(null);
            dispatch(clearReportTypeVersions());
        }
    }, [modules.length]);

    const addModules = useCallback(
        (options: AddModuleOptions) => {
            const callback = response => {
                if (!response) return;
                cancelParentModal();
            };
            !!options.id &&
                !!options.target &&
                dispatch(addModuleAction(options)).then(callback);
        },
        [dispatch, cancelParentModal],
    );

    const wrappedAddmodules = useCallback(
        (options: AddModuleOptions) => {
            if (options.type !== "submodule") {
                addModules(options);
                return;
            }
            const possibleParents = modules.flatMap(category =>
                category.moduleInstances.filter(
                    module => module.moduleId === options.parent?.id,
                ),
            );
            if (!possibleParents.length) return addModules(options);
            if (possibleParents.length === 1 && options.parent) {
                return addModules({
                    ...options,
                    parent: {
                        ...options.parent,
                        instanceId: possibleParents[0].instanceId,
                    },
                });
            } else {
                setSubInNeedOfParent(options);
                setAvailableParents(possibleParents);
            }
        },
        [addModules, modules],
    );

    const setOpen = (moduleId: string) => {
        const update = openElements.includes(moduleId)
            ? openElements.filter(id => id !== moduleId)
            : openElements.concat(moduleId);
        setOpenElements(update);
    };

    return (
        <Fragment>
            <Modal
                id={`${id}-mdl-select-parent`}
                title="Aan welke groep wil je deze submodule toevoegen?"
                isOpen={!!subInNeedOfParent}
                primaryButton={{
                    text: "Toevoegen aan groep",
                    action: () => addModules(subInNeedOfParent),
                    disabled: !subInNeedOfParent?.parent?.instanceId,
                }}
                secondaryButton={{
                    action: cancelParentModal,
                }}
            >
                <Box mx={3}>
                    <TextField
                        select
                        fullWidth
                        variant="outlined"
                        id={`${id}-mdl-select-parent-input`}
                        name="moduleGroupTarget"
                        value={subInNeedOfParent?.parent?.instanceId || ""}
                        onChange={ev =>
                            setSubInNeedOfParent(sub => ({
                                ...sub,
                                parent: {
                                    ...sub.parent,
                                    instanceId: ev.target.value,
                                },
                            }))
                        }
                    >
                        <MenuItem value="">-</MenuItem>
                        {isArrayWithContent(availableParents) &&
                            availableParents.map(parent => (
                                <MenuItem
                                    key={parent.instanceId}
                                    value={parent.instanceId}
                                >
                                    {parent.displayName}
                                </MenuItem>
                            ))}
                    </TextField>
                </Box>
            </Modal>
            <Grid container spacing={3} className={style.wrapper}>
                <Grid item xs={6}>
                    <Box mb={2}>
                        <Typography type="headline6">
                            Kies je verslagtype
                        </Typography>
                    </Box>
                    <FormControl fullWidth variant="outlined">
                        <Box display="flex" alignItems="center">
                            <Select
                                fullWidth
                                disabled={
                                    formsVersionsForInspectionStore.loading
                                }
                                displayEmpty
                                id={`${id}-"select-type`}
                                value={selectedReportType || ""}
                                onChange={event => {
                                    //handleSelectReportType(event.target.value);
                                    setSelectedReportType(event.target.value);
                                    setSelectedReportTypeVersion(null);
                                }}
                                inputProps={{
                                    "aria-label": "Without label",
                                }}
                            >
                                {reportTypes && reportTypes.length > 0 ? (
                                    reportTypes.map(type => (
                                        <MenuItem
                                            key={type.id}
                                            id={`${id}-${type.id}`}
                                            value={type}
                                        >
                                            {type.displayName}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem
                                        disabled
                                        value=""
                                        key={`${id}-no-type`}
                                    >
                                        <em>Lege lijst</em>
                                    </MenuItem>
                                )}
                            </Select>

                            {formsVersionsForInspectionStore.loading && (
                                <CircularProgress size={24} />
                            )}
                        </Box>
                    </FormControl>
                    <Box mb={2} mt={2}>
                        <Typography type="headline6">
                            Kies je verslagversie
                        </Typography>
                    </Box>
                    <FormControl fullWidth variant="outlined">
                        <Box display="flex" alignItems="center">
                            <Select
                                fullWidth
                                disabled={formsSummaryStore?.loading}
                                displayEmpty
                                id={`${id}-select-version`}
                                value={selectedReportTypeVersion || ""}
                                onChange={event =>
                                    setSelectedReportTypeVersion(
                                        event.target.value,
                                    )
                                }
                                inputProps={{
                                    "aria-label": "Without label",
                                }}
                            >
                                {reportTypeVersions &&
                                reportTypeVersions.length > 0 ? (
                                    reportTypeVersions.map((version, index) => (
                                        <MenuItem
                                            key={`${index}-${version.versionId}`}
                                            id={`${id}-${index}-${version.versionId}`}
                                            value={version}
                                        >
                                            {version.versionName
                                                ? version.versionName
                                                : ""}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem
                                        disabled
                                        value=""
                                        key={`${id}-no-version`}
                                    >
                                        <em>Geen versies beschikbaar</em>
                                    </MenuItem>
                                )}
                            </Select>

                            {formsVersionsForInspectionStore?.loading && (
                                <CircularProgress size={24} />
                            )}
                        </Box>
                    </FormControl>
                    <Box mt={2} className={style.reportTypesListBlock}>
                        {selectedReportType &&
                            selectedReportTypeVersion &&
                            formsWithVersion &&
                            formsWithVersion?.modules?.length > 0 && (
                                <Fragment>
                                    <LoadingButton
                                        startIcon={
                                            <AddCircleIcon fontSize="inherit" />
                                        }
                                        id={`${id}-btnAddModules`}
                                        color="primary"
                                        variant="text"
                                        loading={addModulesLoading}
                                        fullWidth
                                        onClick={() =>
                                            addModules({
                                                inspectionId:
                                                    assignment.inspectionId,
                                                visitId,
                                                id: selectedReportType.id,
                                                target: "formTemplateId",
                                                type: "module",
                                                versionId:
                                                    selectedReportTypeVersion?.versionId,
                                            })
                                        }
                                    >
                                        Alle modules toevoegen
                                    </LoadingButton>
                                    {formsListLoading && (
                                        <CircularProgress size={24} />
                                    )}
                                    {!formsListLoading && (
                                        <FormsList
                                            className={style.reportTypesList}
                                            id={id}
                                            loading={
                                                addModulesLoading ||
                                                formsListLoading
                                            }
                                            items={
                                                formsWithVersion &&
                                                formsWithVersion.modules.sort(
                                                    sortOnProperty("order"),
                                                )
                                            }
                                            expandedItems={openElements}
                                            onAdd={(
                                                item: *,
                                                type: ModuleType,
                                                parent?: *,
                                            ) =>
                                                wrappedAddmodules({
                                                    inspectionId:
                                                        assignment.inspectionId,
                                                    visitId,
                                                    id: item.id,
                                                    formTemplateId:
                                                        selectedReportTypeVersion.templateId,
                                                    target: "moduleId",
                                                    type,
                                                    parent,
                                                    versionId:
                                                        selectedReportTypeVersion?.versionId,
                                                })
                                            }
                                            nestedListProp="submodules"
                                            onToggleExpand={item =>
                                                setOpen(item.id)
                                            }
                                            countIPDInfringements={true}
                                        />
                                    )}
                                </Fragment>
                            )}
                    </Box>
                </Grid>
                <Grid item md={1}>
                    <Box height="100%" display="flex" justifyContent="center">
                        <Divider orientation="vertical" />
                    </Box>
                </Grid>

                <Grid item xs={6}>
                    <Box
                        mb={2}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box display="flex">
                            <Typography type="headline6">
                                Geselecteerde modules
                            </Typography>
                            <Box ml={1}>
                                <Typography type="body1" color="disabled">
                                    ({selectedModulesNumber})
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    {selectedModulesLoading && <LoadingBox p={3} size={24} />}
                    {!selectedModulesLoading && modules && (
                        <Box className={style.assignedList}>
                            <SelectedModulesList
                                id={`${id}-selected-modules`}
                                modules={modules}
                                assignment={assignment}
                                modulesLoading={addModulesLoading}
                                currentUser={currentUser}
                                visitId={visitId}
                                onDeleteSelectedOrParent={
                                    onDeleteSelectedOrParent
                                }
                                selectedInstanceId={selectedInstanceId}
                                selectedParentInstanceId={
                                    selectedParentInstanceId
                                }
                            />
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Fragment>
    );
};
export default SelectModules;
