// @flow

import style from "./style.module.scss";

import React, { Fragment } from "react";

import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
//icons
import AddCircle from "@material-ui/icons/AddCircle";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import CallSplit from "@material-ui/icons/CallSplit";
import ErrorIcon from "@material-ui/icons/Error";
import ErrorIconOutline from "@material-ui/icons/ErrorOutline";
import Message from "@material-ui/icons/Message";

import { Typography, Marker } from "@components/Shared";

/**
 * ParentModuleItem
 */
type Props = {
    id: string,
    item: *,
    expanded: boolean,
    disabled: boolean,
    onToggle: Function,
    onAdd?: Function,
};
const ParentModuleItem = ({
    id,
    item,
    onAdd,
    onToggle,
    expanded,
    disabled,
}: Props) => {
    const clickHandler = () => onToggle && onToggle(item);

    const calculateTotal = key => {
        const collection = item.submoduleInstances || item.submodules || [];
        return collection.reduce(
            (total, subItem) => total + (subItem[key] || 0),
            0,
        );
    };

    const totalQualityControlItems = calculateTotal("qualityControlItems");
    const totalInfractionCount = calculateTotal("infractionCount");
    const totalAttentionPointsCount = calculateTotal("attentionPointCount");
    const totalExemptionCount = calculateTotal("exemptionCount");

    return (
        <Fragment>
            <ListItem
                id={id}
                onClick={clickHandler}
                disabled={disabled}
                button={!!onToggle}
            >
                <ListItemText disableTypography className={style.textBlock}>
                    <Typography type="subtitle1Regular">
                        {item.displayName}
                    </Typography>

                    <Box display="flex" flexWrap="nowrap">
                        {totalInfractionCount > 0 && (
                            <Marker
                                count={totalInfractionCount}
                                color="error"
                                title="Inbreuk"
                                id={id}
                                Icon={ErrorIcon}
                                className={style.markerCount}
                            />
                        )}
                        {totalAttentionPointsCount > 0 && (
                            <Marker
                                count={totalAttentionPointsCount}
                                color="secondary"
                                title="Aandachtspunt"
                                id={id}
                                Icon={ErrorIconOutline}
                                className={style.markerCount}
                            />
                        )}

                        {totalExemptionCount > 0 && (
                            <Marker
                                id={id}
                                color="secondary"
                                count={totalExemptionCount}
                                title="Afwijking"
                                Icon={CallSplit}
                                className={style.markerSpacer}
                            />
                        )}
                        {totalQualityControlItems > 0 && (
                            <Marker
                                count={totalQualityControlItems}
                                color="primary"
                                title="Opmerking"
                                id={id}
                                Icon={Message}
                                className={style.markerCount}
                            />
                        )}

                        {expanded ? <ExpandLess /> : <ExpandMore />}
                    </Box>
                </ListItemText>
                {onAdd && (
                    <ListItemSecondaryAction>
                        <IconButton
                            id={`${id}-btnAdd`}
                            color="primary"
                            edge="end"
                            disabled={disabled}
                            aria-label="Alle submodules toevoegen"
                            onClick={() => onAdd(item)}
                        >
                            <AddCircle />
                        </IconButton>
                    </ListItemSecondaryAction>
                )}
            </ListItem>
            <Divider />
        </Fragment>
    );
};

export default React.memo<Props>(ParentModuleItem);
